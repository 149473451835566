import React from 'react';
import Header from '../components/Header';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './layout.scss';

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      {children}
    </div>
  );
};

export default Layout;
