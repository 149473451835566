import './Header.scss';

import {
  Button,
  Container,
  Nav,
  Navbar,
} from 'react-bootstrap';

import { Link } from 'gatsby';
import React from 'react';
import logo from '../images/walletgyde-logo.png';

const Header = () => {
  return (
    <Navbar collapseOnSelect expand="md">
      <Container>
        <Navbar.Brand href="/">
          <img id="logo" src={logo} height="50" alt="Walletgyde" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/howitworks">How It Works</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/matching">Get Matched</Nav.Link>
            <Nav.Link href="/onBoardCoaches">New Coach</Nav.Link>
          </Nav>
          <Nav className="external-links">
            <a
              href="https://walletgydeblog.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
          </Nav>
          <Nav>
            <a href="https://app.walletgyde.com/" rel="noopener noreferrer">
              <Button variant="outline-primary">Dashboard</Button>
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
