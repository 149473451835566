// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-howitworks-js": () => import("./../../../src/pages/howitworks.js" /* webpackChunkName: "component---src-pages-howitworks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matching-index-js": () => import("./../../../src/pages/matching/index.js" /* webpackChunkName: "component---src-pages-matching-index-js" */),
  "component---src-pages-matching-results-js": () => import("./../../../src/pages/matching/results.js" /* webpackChunkName: "component---src-pages-matching-results-js" */),
  "component---src-pages-on-board-coaches-index-js": () => import("./../../../src/pages/onBoardCoaches/index.js" /* webpackChunkName: "component---src-pages-on-board-coaches-index-js" */),
  "component---src-pages-on-board-coaches-results-js": () => import("./../../../src/pages/onBoardCoaches/results.js" /* webpackChunkName: "component---src-pages-on-board-coaches-results-js" */)
}

